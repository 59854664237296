<!-- eslint-disable no-unused-vars -->
<script setup>
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import store from '../../../state/store';
import { callApi } from '../../../msal/msalApi';
import { reactive, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'

const { email, id } = useRoute().params;
const {dateBilling,franchise_ID,fromBilling, inputSearch, selectLocation, selectFranchise, unassignedOnly, selectedFilter, activeDate, calendarDate, monitoringFilter } = useRoute().query;

let data = reactive({
  firstName: '',
  lastName: '',
  email: '',
  selectGroup: '',
  selectLocation: '',
  selectRol: '',
  practiceGroupArray: [],
  locationArray: [],
  rolesArray: [],

  userEditData: [],
  userLocations: [],
  availableCoaches: [],
  supplements: [],
  program: [],


  practiceRol: '',
  accountId: '',
  franchise_ID: '',
  franchiseName: '',

  locationID: '',

  url: '',

  address1: '',
  address2: '',
  city: '',
  locationCity: '',
  locationGuid: '',
  mobilePhone: '',
  phone: '',
  state: '',
  userAccount_ID: '',
  zip: '',

  cityAndState: '',


  startDay: '',
  endDay: '',
  coach: '',
  clientReports: [],
  isMonitoringActivated: false,
  monitoring: [],
  statusText: '',
  buttonColor: '',
  buttonText: '',

  monitoringStatus: '',
  clientStatus: '',

  loading: false,
  showLoading: false,

  supplementType1: [],
  supplementType2: [],
  supplementType3: [],
  billingStartDate: '',
  billingEndDate: ''

})


async function getPracticeGroup() {
  data.url = `${process.env.VUE_APP_WLT_API}Associate/PracticeGroupMembersPaged?email=${store.getters.getEmail}&sortByList[0].Name=FranchiseName&sortByList[0].Direction=Asc&sortByList[1].Name=LastName&sortByList[1].Direction=Desc`;
  const userData = `${process.env.VUE_APP_WLT_API}Client/InfoGet?email=${email}`;
  const userProgram = `${process.env.VUE_APP_WLT_API}UserProgram?email=${email}`;
  const userSupplements = `${process.env.VUE_APP_WLT_API}Supplements?userEmail=${email}`;
  const userReports = `${process.env.VUE_APP_WLT_API}Client/Reports?clientEmail=${email}`;
  if (data.selectGroup) {
    data.url += `&franchiseIdFilters[0]=${data.selectGroup}`;
  } else if (data.selectLocation) {
    data.url += `&franchiseLocationIdFilters[0]=${data.selectLocation}`;
  }

  try {

    const [response, response2, response3, response4, reports] = await Promise.all([
      callApi(data.url, store.getters.getToken),
      callApi(userData, store.getters.getToken),
      callApi(userSupplements, store.getters.getToken),
      callApi(userProgram, store.getters.getToken),
      callApi(userReports, store.getters.getToken),
    ]);

    const { franchiseSelections, franchiseLocations, practiceRoles } = response;

    const {
      address1,
      address2,
      city,
      email,
      firstName,
      franchiseID,
      franchiseName,
      lastName,
      locationCity,
      locationGuid,
      locationID,
      locationName,
      mobilePhone,
      phone,
      state,
      userAccount_ID,
      zip,
      availableCoaches
    } = response2;

    data.practiceGroupArray = franchiseSelections;
    data.locationArray = franchiseLocations;
    data.rolesArray = practiceRoles;

    data.availableCoaches = availableCoaches
    data.supplements = response3
    data.program = response4
    data.isMonitoringActivated = response4.isMonitoringActivated
    data.monitoring = response4

    data.firstName = firstName;
    data.lastName = lastName;
    data.email = email;

    data.accountId = userAccount_ID
    data.franchise_ID = franchiseID
    data.franchiseName = franchiseName

    data.address1 = address1
    data.address2 = address2
    data.city = city
    data.locationCity = locationName
    data.locationGuid = locationGuid
    data.mobilePhone = mobilePhone
    data.phone = phone
    data.state = state
    data.userAccount_ID = userAccount_ID
    data.zip = zip
    data.address1 = address1
    data.address2 = address2
    data.cityAndState = city + ' ' + state

    data.startDay = new Date(data.program.programStartDate).toLocaleDateString()
    data.endDay = new Date(data.program.programEndDate).toLocaleDateString()

    data.clientReports = reports.reports
    data.clientStatus = response4.clientStatus
    data.monitoringStatus = response4.monitoringStatus

    data.showLoading = true,
      data.loading = true

    const assignedCoachesNames = response2.availableCoaches
      .filter(coach => coach.isAssigned === 1)
      .map(coach => data.coach = `${coach.coachFirstName} ${coach.coachLastName}`)

    data.supplementType1 = response3
      .filter(supplement => supplement.supplementTypeID === 1)
    data.supplementType2 = response3
      .filter(supplement => supplement.supplementTypeID === 2).slice(0, 4);
    data.supplementType3 = response3
      .filter(supplement => supplement.supplementTypeID === 2).slice(4)

    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    console.log(response4, reports)
    console.log(data.isMonitoringActivated)
    console.log(data.monitoringStatus, data.clientStatus)

    data.billingStartDate = formatDate(response4.billingCycleStartDate);
    data.billingEndDate = formatDate(response4.billingCycleEndDate);

    console.log(franchise_ID,dateBilling,fromBilling)



  } catch (error) {
    console.error(error);
    data.showLoading = false,
      data.loading = false
  }
  //getClientStatus()
  //getMonitoringStatus()
}

function getClientStatus(clientStatus) {
  let status = '';

  if (clientStatus === 0) {
    status = 'Unknown';
  } else if (clientStatus === 1) {
    status = `Active (Program ends on ${data.endDay})`;
  } else if (clientStatus === 2) {
    status = `In-active ${data.endDay === '1/1/1' ? '' : "Program ended on " + data.endDay}`;
  }

  return status;
}

function getMonitoringStatus(monitoringStatus) {
  const now = new Date();
  const monitoredUntilDate = formatDate(data.monitoring.monitoredUntilDate);
  let status = '';


  if (monitoringStatus === 1) {
    status = 'Monitored';
    data.alertColor = "btn-success";

  } else if (monitoringStatus === 2) {
    status = ` Deactivation in process.`;
    data.alertColor = "btn-warning";

  } else if (monitoringStatus === 0) {
    status = 'Not Monitored';
    data.alertColor = "btn-danger";

  } else {
    status = 'Unknown';
  }

  return status;
}


// Función para formatear las fechas en el arreglo
function formatDate(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}



async function sendUpdateForm() {
  try {
    const { firstName, lastName, email, address1, address2, city, state, zip, phone } = data;
    const url = `${process.env.VUE_APP_WLT_API}Client/Update?email=${email}&FirstName=${firstName}&LastName=${lastName}&Email=${email}&Address1=${address1}&Address2=${address2}&City=${city}&State=${state}&Zip=${zip}&Phone=${phone}`;

    console.log(url);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${store.getters.getToken}`
      }
    });

    if (response.ok) {
      Swal.fire({
        html: `<p class="h6"><p class="h5 fw-bold">${data.email}</p> has been updated</p>`,
        icon: 'success'
      });
    } else {
      Swal.fire('Error!', '', 'error');
    }

    getPracticeGroup();
  } catch (error) {
    Swal.fire('Error!', '', 'error');
    console.log(error);
  }
}

async function AssignCoach(coachData) {
  const { coachEmail, coachFirstName, coachLastName } = coachData
  console.log(coachEmail, coachFirstName, coachLastName)
  try {
    const { email } = data;
    const url = `${process.env.VUE_APP_WLT_API}AssignCoach/ByEmail?clientEmail=${email}&coachEmail=${coachEmail}`;

    console.log(url);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${store.getters.getToken}`
      }
    });

    if (response.ok) {
      Swal.fire({
        html: `<p class="h6"><p class="h5 fw-bold">The coach ${coachFirstName + ' ' + coachLastName} has been assigned to ${email}.</p>`,
        icon: 'success'
      });
    } else {
      Swal.fire('Error!', '', 'error');
    }

    getPracticeGroup();
  } catch (error) {
    Swal.fire('Error!', '', 'error');
    console.log(error);
  }
}



async function activeMonitoring() {
  try {
    const url = `${process.env.VUE_APP_WLT_API}Client/${data.monitoringStatus === 1 ? "DeactivateMonitoring" : "ActivateMonitoring"}?clientEmail=${data.email}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${store.getters.getToken}`
      }
    });

    if (response.ok) {
      Swal.fire(`<p class="h5"> ${data.email} monitoring has been ${data.monitoringStatus === 1 ? 'Deactivate' : 'Activate'}</p>`, '', 'success');
      getPracticeGroup();
    } else {
      Swal.fire('Error!', '', 'error');
    }

    console.log(response);
  } catch (error) {
    Swal.fire('Saved!', '', 'error');
    console.log(error.message);
  }
}

function calcBillingEndDate(billingStartDate) {
  let firstDay = billingStartDate.getDate();  // Get the day of the month (1-31)
  let currentMonth = billingStartDate.getMonth() + 1;  // Get the month (0-11, so add 1 to get 1-12)
  let nextMonth = (currentMonth % 12) + 1;  // Get the next month (if current month is 12, next month should be 1)

  // Create a new date object for the first day of the "month after the next month"
  let firstDayOfNextNextMonth = new Date(billingStartDate.getFullYear(), nextMonth % 12, 1);

  // Subtract one day to get the last day of the next month
  firstDayOfNextNextMonth.setDate(firstDayOfNextNextMonth.getDate() - 1);

  let lastDayOfNextMonth = firstDayOfNextNextMonth.getDate();

  // Formulate a date that is nextMonth and firstDay
  // If firstDay is greater than or equal to lastDayOfNextMonth, set it to the first day of the month after nextMonth
  let formulatedDate;
  if (firstDay >= lastDayOfNextMonth) {
    formulatedDate = new Date(billingStartDate.getFullYear(), nextMonth % 12, 1);
  } else {
    formulatedDate = new Date(billingStartDate.getFullYear(), (currentMonth % 12), firstDay);
  }

  // Create a new date that is one day before formulatedDate
  let billingEndDate = new Date(formulatedDate);
  billingEndDate.setDate(formulatedDate.getDate() - 1);

  // Return the end date
  return formatDate(billingEndDate);
}


onMounted(() => {
  getPracticeGroup()

  if (data.selectGroup) {
    data.url += `&franchiseIdFilters[0]=${data.selectGroup}`;
  }
})
</script>
    
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <h2>Edit Client</h2>
    <div class="container">
    <p class="fw-bold"> {{ data.firstName+ ' ' + data.lastName+ ' (' + data.locationCity+ ' ' + 'location )'}}</p>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button :class="`nav-link ${!fromBilling ? 'active' : ''} fw-bold`" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
          type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Info</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link fw-bold" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
          type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"
          :disabled="!data.loading">Program</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link fw-bold" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
          type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"
          :disabled="!data.loading">Coach</button>
      </li>

      <li class="nav-item" role="presentation">
        <button class="nav-link fw-bold" id="reports-tab" data-bs-toggle="tab" data-bs-target="#reports-tab-pane"
          type="button" role="tab" aria-controls="reports-tab-pane" aria-selected="false"
          :disabled="!data.loading">Reports</button>
      </li>
      <li class="nav-item " role="presentation">
        <button :class="`nav-link ${fromBilling ? 'active' : ''} fw-bold`" id="reports-tab" data-bs-toggle="tab" data-bs-target="#monitoring-tab-pane"
          type="button" role="tab" aria-controls="monitoring-tab-pane" aria-selected="false"
          :disabled="!data.loading">Monitoring
          <div
            :class="{ 'circle green': data.alertColor === 'btn-success', 'circle red': data.alertColor === 'btn-danger', 'circle yellow': data.alertColor === 'btn-warning' }">
          </div>
        </button>

      </li>
      <li v-if="!fromBilling" class="nav-item back-button2" role="presentation">
        <router-link :to="{
          path: '/client',
          query: {
            inputSearch,
            selectLocation,
            selectFranchise,
            unassigned,
            selectedFilter,
            unassignedOnly,
            activeDate,
            calendarDate,
            monitoringFilter,
          }
        }">
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24"
            style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
            <path d="M9 10h6c1.654 0 3 1.346 3 3s-1.346 3-3 3h-3v2h3c2.757 0 5-2.243 5-5s-2.243-5-5-5H9V5L4 9l5 4v-3z">
            </path>
          </svg>
        </router-link>


      </li>

      <li v-else class="nav-item back-button2" role="presentation">
        <router-link :to="{
          path: '/view-invoice',
          query: {
            date:dateBilling,
            franchise_ID
          }
        }">
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24"
            style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
            <path d="M9 10h6c1.654 0 3 1.346 3 3s-1.346 3-3 3h-3v2h3c2.757 0 5-2.243 5-5s-2.243-5-5-5H9V5L4 9l5 4v-3z">
            </path>
          </svg>
        </router-link>


      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div :class="`tab-pane ${!fromBilling ? 'show active' : 'fade'}`" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
        <div class="container mb-5">

          <div class="spinner-overlay text-center mt-3" v-if="!data.loading">
            <div class="spinner-border text-primary  custom-spinner" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div>Loading...</div>
          </div>


          <form v-if="data.showLoading">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-md-5 mb-3 m-form">
                <div class="row">
                  <div class="col  mt-1">
                    <label class="form-label" for="">Practice Group</label>
                    <input required type="text" class="form-control" id="Practice" placeholder="Practice Group Name"
                      disabled v-model="data.franchiseName">
                  </div>
                  <div class="col  mt-1">
                    <label class="form-label" for="">Location name</label>
                    <input required type="text" class="form-control" id="Practice" placeholder="city Name"
                      v-model="data.locationCity" disabled>
                  </div>
                </div>
                <div class="row mt-2 ">
                  <div class="col ">
                    <label class="form-label" for="">First name</label>
                    <input required type="text" class="form-control" id="firstName" v-model="data.firstName">
                  </div>
                  <div class="col ">
                    <label class="form-label" for="Last name"> Last Name</label>
                    <input required type="text" class="form-control" id="lastName" v-model="data.lastName">
                  </div>

                </div>
                <div class="row">
                  <div class="col  mt-1">
                    <label class="form-label" for="state">Address 1</label>
                    <input required type="text" class="form-control" id="address1" v-model="data.address1">
                  </div>

                  <div class="col  mt-1">
                    <label class="form-label" for="zip">Address 2</label>
                    <input required type="text" class="form-control" id="address2" v-model="data.address2">
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col  mt-1">
                    <label class="form-label" for="">City</label>
                    <input required type="text" class="form-control" id="Practice" placeholder="city Name"
                      v-model="data.city">
                  </div>
                  <div class="col  mt-1">
                    <label class="form-label" for="state">State</label>
                    <input required type="text" class="form-control" id="state" v-model="data.state">
                  </div>
                </div>


                <div class="row">

                  <div class="col  mb-1">
                    <label class="form-label" for="phone">Phone</label>
                    <input required type="text" class="form-control" id="phone" v-model="data.phone">
                  </div>

                  <div class="col  mt-1">
                    <label class="form-label" for="zip">Zip</label>
                    <input required type="text" class="form-control" id="zip" v-model="data.zip">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label for="email" class="form-label">Email</label>
                    <input required type="email" class="form-control" id="email" v-model="data.email">
                  </div>
                </div>



                <div class="row">

                  <div class="col d-flex flex-row">

                    <button class="btn btn-success w-100 mt-2" type="submit"
                      @click.prevent="sendUpdateForm">Update</button>
                  </div>

                </div>

              </div>


            </div>
          </form>
        </div>
      </div>

      <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
        <p class="fw-bold mt-3 h5 mb-4">{{ data.program.programDescription }} - {{ data.program.subProgramTypeDescription
        }}
        </p>
        <p v-if="data.program.programDescription != 'Not Enrolled'" class="fw-bold h5">
          Start: {{ data.startDay }} - End: {{ data.endDay }}
        </p>

        <div class="mb-5 h5  border border-light">

          <div class="table-responsive">
            <table class="table caption-top table-light  table-striped table-hover text-center">
              <caption class="text-center">
                <p class="bg-primary pb-2 pt-2 text-white table-text h4">
                  PROGRAM CORE NUTRITIONAL SUPPLEMENTS
                </p>
                <p class="text-primary fw-bold table-text h5">These are to supplement the body's needs</p>
              </caption>
              <thead>

                <tr>
                  <th scope="col">PRODUCT</th>
                  <th scope="col">DOSAGE</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(supplement, index) in data.supplementType1" :key="index">
                  <td>{{ supplement.supplementName }}</td>
                  <td>{{ supplement.dosageInstructions }}</td>
                </tr>

              </tbody>
            </table>
          </div>

          <div class="table-responsive">
            <table class="table caption-top table-light  table-striped table-hover text-center">
              <caption class="text-center">
                <p class="bg-primary text-white h4 pb-2 pt-2 ">PERSONALIZED FOUNDATIONAL SUPPLEMENTS</p>
                <p class="text-primary fw-bold h5">Included with the program are your unique combination of
                  supplements
                  designed to support your body throughout the WLD Program to help you achieve your personal goals. All
                  Primary Nutritional Products are included in your program.</p>
              </caption>
              <thead class="">
                <tr>
                  <th scope="col">PRODUCT</th>
                  <th scope="col">DOSAGE</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(supplement, index) in data.supplementType2" :key="index">
                  <td>{{ supplement.supplementName }}</td>
                  <td>{{ supplement.dosageInstructions }}</td>
                </tr>

              </tbody>
            </table>
          </div>

          <div class="table-responsive" v-if="data.supplementType3.length > 0">
            <table class="table caption-top table-light table-striped table-hover text-center">
              <caption class="text-center">
                <p class="bg-primary text-white table-text h4 pb-2 pt-2 ">OPTIONAL SUPPLEMENTS</p>
                <p class="text-primary fw-bold table-text h5">These discretionary supplements are not required for your
                  program, but are
                  recommended to optimize your wellness and are available at an additional cost.</p>
              </caption>
              <thead class="">
                <tr>
                  <th scope="col">PRODUCT</th>
                  <th scope="col">DOSAGE</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(supplement, index) in data.supplementType3" :key="index">
                  <td class="w-25">{{ supplement.supplementName }}</td>
                  <td>{{ supplement.dosageInstructions }}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>

      <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-5 mt-3 mb-2">
              <p class="fw-bold h5">
                Client Sign-up Location: {{ data.locationCity }}
              </p>
              <p class="fw-bold h5">
                Assigned Coach: {{ data.coach }}
              </p>
              <div class="mt-5">
                <div class="table-container">
                  <table class="table table-primary table-responsive">
                    <!-- Encabezado de la tabla -->
                    <thead class="table-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Coach</th>
                        <th scope="col">Active Clients</th>
                      </tr>
                    </thead>
                    <!-- Cuerpo de la tabla -->
                    <tbody>
                      <tr v-for="coach in data.availableCoaches" :key="coach.coach_UserAccount_ID">
                        <td>
                          <button class="btn text-primary fw-bold" @click="AssignCoach(coach)">Assign</button>
                        </td>
                        <td>{{ coach.coachFirstName }} {{ coach.coachLastName }}</td>
                        <td>{{ coach.activeClientCount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="reports-tab-pane" role="tabpanel" aria-labelledby="reports-tab" tabindex="0">
        <div class="container">
          <div class="table-responsive">
            <p class="h4 mb-4 mt-4">Reports</p>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Report Type</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(report, index) in data.clientReports" :key="index">
                  <th scope="row">{{ formatDate(report.reportDate) }}</th>
                  <td>Intelligence</td>
                  <td>
                    <a target="_blank"
                      :href="`https://wld-web-portal.azurewebsites.net/Report/analysis/${report.id}?reportFormat=HTML&emailLink=True&getFreshReport=True&targetViewPort=MOBILE`"
                      class="btn btn-primary">></a>

                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div :class="`tab-pane ${fromBilling ? 'show active' : 'fade'}`"  id="monitoring-tab-pane" role="tabpanel" aria-labelledby="monitoring-tab" tabindex="0">
        <div class="container">
          <p class="h3 mt-4 mb-3">Monitoring</p>

          <p><span class="fw-bold">Client Status:</span> {{ getClientStatus(data.clientStatus) }}</p>

          <p><span class="fw-bold">Monitoring Status:</span> {{ getMonitoringStatus(data.monitoring.monitoringStatus) }}
          </p>
          <p v-if="data.monitoring.monitoringStatus === 2"><span class="fw-bold">Deactivation occurs at the end of billing
              cycle: </span> {{ formatDate(data.monitoring.monitoredUntilDate) }}</p>

          <p v-if="data.monitoring.monitoringStatus === 1"><span class="fw-bold">Current billing cycle from: </span>
            {{ data.billingStartDate }} <span class="fw-bold">to</span> {{ data.billingEndDate }}
          </p>
          <p v-if="data.monitoring.monitoringStatus === 0"><span class="fw-bold">Monitoring Ended: </span> {{ data.endDay
          }}</p>

          <button v-on:click="activeMonitoring"
            :class="data.monitoringStatus === 0 || data.monitoringStatus === 2 ? 'btn btn-success' : 'btn btn-danger'">
            {{ data.monitoringStatus === 1 ? 'Deactivate Monitoring' : 'Activate Monitoring' }}
          </button>

          <div :style="{ backgroundColor: data.alertColor, width: '30px', height: '30px', borderRadius: '50%' }"></div>
        </div>
      </div>


    </div>
  </div>
  </Layout>


 
</template>

<style>
:root {
  --fixsize-margin-left: 14rem;
  --fixsize-width: 75%;
  --m-form-margin-left: 15rem;
  --m-form-width: 50%;
}

#contenedorLocation {
  height: 300px;
  overflow-y: scroll;
}

.list-item {
  list-style: none;
}
/* Estilos para el contenedor de la tabla */
.table-container {
  max-height: 300px; /* Altura máxima del contenedor */
  overflow-y: auto; /* Permite el scroll vertical si el contenido excede la altura */
}


@media screen and (min-width: 876px) {
  .fixsize {
    margin-left: var(--fixsize-margin-left);
    width: var(--fixsize-width);
  }

  .m-form {
    margin-left: var(--m-form-margin-left);
    width: var(--m-form-width);
  }

  .back-button {
    font-size: 3.5rem;
    margin-right: 10rem;
  }

  .back-button2 {
    margin-left: 35rem;
  }
}

@media screen and (max-width: 917px) {
  .fixsize {
    margin-left: 0;
    width: 100%;
  }

  .m-form {
    margin-left: 0;
    width: 100%;
  }

  .table-text {
    font-size: 16px;
    line-height: 1.5;
  }

  .back-button {
    font-size: 2.2rem;
    margin-right: 1rem;
  }

  .back-button2 {
    margin-left: 35rem;
  }
}

.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 50%;
}

.green {
  background-color: green;
  box-shadow: 0 0 5px 2px rgba(0, 128, 0, 0.5);
}

.red {
  background-color: red;
  box-shadow: 0 0 5px 2px rgba(128, 0, 0, 0.5);
}

.yellow {
  background-color: darkkhaki;
  box-shadow: 0 0 5px 2px rgba(255, 255, 0, 0.5);
}
</style>
